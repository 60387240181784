<template>
  <div>
    <div
      class="object-cover relative w-24 h-24 overflow-hidden bg-gray-300 border-0 rounded-full"
    >
      <img
        :src="getAvatar"
        class="object-cover w-24 h-24 rounded-full"
        :class="avatarClass"
      />
    </div>
    <div
      class="relative z-10 grid w-24 h-6 px-3 py-px -mt-3 text-xs font-semibold uppercase bg-white rounded-full shadow-md place-items-center text-oBlack"
    >
      {{ id }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarId',
  props: ['id', 'avatar', 'avatarClass'],
  computed: {
    getAvatar: function() {
      return this.avatar ?? require('@/assets/img/avatar-male.png')
    },
  },
}
</script>

<style></style>
