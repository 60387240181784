<template>
  <base-layout>
    <VehicleAddEdit
      :es-id="addEdit.esId"
      :stepper-mode="addEdit.stepperMode"
      :stepper-step="addEdit.stepperStep"
      :primary-key="addEdit.primaryKey"
      :primary-key-lock="addEdit.primaryKeyLock"
      :raw-data="addEdit.rawData"
      :busy="addEdit.busy"
      @refresh="
        $store.dispatch('vehicleDetails/fetchData', {
          type: 'details',
          primaryKey: `${addEdit.primaryKey}`,
        })
      "
    />

    <template v-if="$acl.canView('vehicles')">
      <details-page-title
        title="Vehicle Management"
        dropdown-text-button="Vehicle Status"
        :details-id="getImei"
        :truncate="false"
        @activate="handleActivation()"
        @deactivate="handleDeactivation()"
      >
        <template #details> #{{ getImei }} </template>
        <!-- <status-dropdown
        :endpoint="getStatusEndpoint"
        method="PUT"
        :statusCurrent="getStatus"
        statusKeyActivate="user_status"
        statusValueActivate="A"
        statusKeyDeactivate="user_status"
        statusValueDeactivate="D"
      /> -->
        <!-- <div
        class="flex items-center justify-between w-64 h-12 px-5 text-sm transition duration-150 ease-in-out bg-white border rounded-full shadow-md cursor-pointer text-oDark focus:outline-none focus:shadow-solid"
        @click="handleVehicleLockUnlock"
      >
        <div class="flex items-center">
          <span class="mr-2 text-oDark">Vehice Status</span>
          <TToggle
            variant="lock"
            :disabled="true"
            v-model="vehicleLockStatus"
          />
        </div>
        <div class="flex items-center w-16 h-10 px-2 font-medium vld-parent">
          <loading
            :height="25"
            color="#323740"
            v-if="lockReq"
            :active="true"
            loader="bars"
            :is-full-page="false"
          />
          <span v-if="!lockReq">{{ getVehicleLockStatusText }}</span>
        </div>
      </div> -->

        <div class="sm:flex items-center justify-center xs:text-center">
          <VehicleMoreSmsActions
            v-if="header && header.lock.iot_category === 'JIMI_BL10S'"
            :vehicleId="id"
            class="m-1"
          />

          <VehicleMoreActions
            v-if="header"
            class="m-1"
            :data="header"
            :variant="`emphasize`"
            :vehicle-type="`scooter`"
            :current-status="vehicleLockStatus"
            :redirectPath="getPath"
            @edit="onOpenES({ primaryKey: $event.vehicleId })"
            @change="() => console.log('change')"
            @force-sync="() => console.log('force')"
            @delete:failed="onDeleteFailed"
            @delete:succeeded="onDeleteSucceeded"
          />
        </div>
      </details-page-title>

      <content-section>
        <div class="grid items-center grid-cols-4 mt-4 gap-y-4 lg:grid-cols-12">
          <display-avatar-id
            :id="getQRCode"
            :avatar="getScooterImage"
            avatarClass="mt-1 h-auto w-auto"
            class="col-span-2 mr-6"
          />
          <div class="flex items-center col-span-2">
            <vertical-devider />
            <battery-status :level="getBatteryLevel" class="mr-6" />
          </div>

          <div
            class="flex items-center col-span-4 py-5 border-t border-b md:justify-center sm:justify-start md:border-0 md:py-0 md:col-span-3"
          >
            <vertical-devider class="hidden lg:block" />
            <div class="flex flex-col mr-6 space-y-3">
              <vehicle-flags
                :flags="getFlags"
                :event-enabled="true"
                :fallback="true"
                :task="getTask"
                @parking="handleFlagEvent('parking')"
                @reservation="handleFlagEvent('reservation')"
                @on-ride="handleFlagEvent('on-ride')"
              />
              <div
                class="block mt-1 font-normal text-14px md:hidden text-oLightGray"
              >
                Last Updated: {{ getLastLocationUpdatedAt }}
              </div>
            </div>
          </div>

          <!-- <div class="flex items-center col-span-2 md:hidden">
          <vertical-devider />
          <div class="flex flex-col justify-center mr-6 space-y-1">
            <div class="font-medium text-oGray text-14px">
              Last Location Update
            </div>
            <div class="font-normal text-oLightGray text-14px">
              {{ getLastLocationUpdatedAt }}
            </div>
          </div>
        </div> -->

          <div class="flex items-center gap-1 col-span-2 ">
            <vertical-devider class="hidden lg:block" />
            <ripple-status :status="getVehicleStatus" />

            <NetworkStrength
              :value="getVehicleNetworkStrength"
              disable-text
              class="-mt-4"
            />
          </div>

          <div class="flex items-center col-span-2">
            <vertical-devider />
            <div>
              <oto-map-button
                variant="teal"
                text="View Location"
                @click="showGoogleMapModal"
              />
              <div
                class="hidden mt-1 font-normal text-14px md:block text-oLightGray"
              >
                Last Updated: {{ getLastLocationUpdatedAt }}
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 -mb-3 full-mode" v-if="tabs">
          <anchor-tabs :tabs="tabs" />
        </div>
        <div class="mt-5 mb-3 responsive-mode" v-if="tabs">
          <t-rich-select
            :options="tabs"
            valueAttribute="href"
            textAttribute="text"
            :hideSearchBox="true"
            @change="changeRoute"
            v-model="routeValue"
          />
        </div>
      </content-section>

      <vehicle-flag-popup />

      <google-map-modal-alt
        :markerIsUpdatable="false"
        :variant="`vehicle`"
        :title="`Vehicle On The Map`"
        :subtitle="getMapVehicleSubtitle"
      />

      <router-view />
    </template>
    <div v-else class="py-5 font-bold text-center text-gray-600">
      You don't have permission to see data. Please Ask your admin to give you
      permission for this screen.
    </div>
  </base-layout>
</template>

<script>
// import { VehicleResource } from '@/resources/VehicleResource'

import { VehicleConfig } from '@/config/VehicleConfig'
import { StatusConfig } from '@/config/StatusConfig'
import { useEndpoints } from '@/composables'
import VehicleAddEdit from '@/composites/vehicle/add-edit/VehicleAddEdit.vue'

import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle'

import DisplayAvatarId from '@/components/ui/DisplayAvatarId'
import AnchorTabs from '@/components/tabs/AnchorTabs'
import BatteryStatus from '@/components/badge/BatteryStatus.vue'
import VerticalDevider from '@/components/ui/VerticalDevider.vue'
import OtoMapButton from '@/components/ui/OtoMapButton'
import RippleStatus from '@/components/badge/RippleStatus.vue'
// import StatusDropdown from "@/components/dropdown/StatusDropdown";

import VehicleFlags from '@/components/badge/VehicleFlags'
import VehicleFlagPopup from '@/views/vehicle/VehicleFlagPopup.vue'

import GoogleMapModalAlt from '@/components/modals/GoogleMapModalAlt'
// import dayjs from "dayjs";
import { EventBus } from '@/utils/EventBus'
import { VehicleMoreActions } from '@/composites/vehicle'
import { VehicleMoreSmsActions } from '@/composites/vehicle'

import { NetworkStrength } from '@/components/badge'
import { mapState } from 'vuex'

export default {
  name: 'ViewVehicle',
  components: {
    BaseLayout,
    ContentSection,
    DetailsPageTitle,
    DisplayAvatarId,
    AnchorTabs,
    BatteryStatus,
    VerticalDevider,
    OtoMapButton,
    RippleStatus,
    // StatusDropdown,
    VehicleFlags,
    VehicleFlagPopup,
    GoogleMapModalAlt,

    VehicleMoreActions,

    NetworkStrength,
    VehicleAddEdit,
    VehicleMoreSmsActions,
  },
  data() {
    return {
      fallbackText: '--',
      routeValue: null,
      tabs: [
        { text: 'Profile', href: this.getTabHref(`profile`) },
        { text: 'Configuration', href: this.getTabHref(`configuration`) },
        { text: 'Logs', href: this.getTabHref(`logs`) },
        { text: 'Rides', href: this.getTabHref(`rides`) },
        { text: 'Revenues', href: this.getTabHref(`revenues`) },
        { text: 'Timeline', href: this.getTabHref(`timeline`) },
        { text: 'IoT Logs', href: this.getTabHref(`iot-logs`) },
        { text: 'Battery', href: this.getTabHref(`battery`) },
        { text: 'Flag', href: this.getTabHref(`flag`) },
        { text: 'Tasks', href: this.getTabHref(`tasks`) },
        { text: 'Reports', href: this.getTabHref(`reports`) },
        { text: 'Health', href: this.getTabHref(`health`) },
        { text: 'Notes', href: this.getTabHref(`notes`) },
      ],
      mapModalState: false,
      mapModalLoc: {},
      lockReq: false,
      addEdit: {
        // EdgeStack component
        esId: 'vehicle-details-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getPath() {
      return this.$route.params.redirectPath
    },
    ...mapState({
      header: (state) => state.vehicleDetails.details,
      primaryKey: (state) => state.vehicleDetails.primaryKey,
    }),

    getImei() {
      return this.header?.lock?.lock_id
    },
    getQRCode() {
      return this.header?.qr_code
    },
    getShortId() {
      return this.id.toString().slice(-5)
    },
    getScooterImage() {
      return require('@/assets/img/scooter.png')
    },
    getBatteryLevel() {
      return this.header?.lock?.power_level || 0
    },
    getVehicleNetworkStrength() {
      return this.header?.lock?.network_signal || 0
    },
    getTask() {
      // return ongoing task for this vehicle
      return this.header?.task
    },
    getFlags() {
      // console.log({
      //   ...this.header.general_flags,
      //   ...this.header.operational_flags,
      //   ...{ missing: true, rebalance: true }
      // });
      return {
        ...this.header?.general_flags,
        ...this.header?.operational_flags,
        ...this.header?.status_flags,
      }
    },
    getVehicleStatus() {
      return this.header?.lock?.is_operational
    },
    vehicleLockStatus: {
      get: function() {
        return this.header?.lock?.is_locked || false
      },
      set: function(val) {
        // todo: send req to server
        // console.log(val);
        this.header.lock.is_locked = val
      },
    },
    getVehicleLockStatusText() {
      return this.vehicleLockStatus === true ? 'Locked' : 'Unlocked'
    },
    getLastLocationUpdatedAt() {
      return this.header?.lock?.last_connected_at
        ? this.$UTCAwareTime(this.header?.lock?.last_connected_at)
        : '--'
    },
    getStatus() {
      return this.header?.vehicle_status === 'A' ? 'A' : 'D'
    },
    getStatusEndpoint() {
      return StatusConfig.api.vehicle(this.id)
    },
    getMapVehicleSubtitle() {
      return 'Last Location Updated On: ' + this.getLastLocationUpdatedAt
    },
  },
  async created() {
    // todo: use mapState instead of doing this
    // await this.$store

    //   .then(() => {
    //     this.header = this.$store.getters['vehicledetail/getData']
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
    this.routeValue = this.tabs?.filter(
      (item) => item.text === 'Profile'
    )[0].href

    if (this.$acl.canView('vehicles')) {
      if (!this.header || this.primaryKey !== this.id) {
        this.$store.dispatch('vehicleDetails/fetchData', {
          type: 'details',
          primaryKey: this.id,
        })
      }
      // await this.$store.dispatch('vehicledetail/fetchData', this.id)
      // this.header = this.$store.getters['vehicledetail/getData']
    }

    if (this.header && this.header.location) {
      let [lat, lng] = this.header.location.split(',')
      this.mapModalLoc = {
        lat: lat,
        lng: lng,
      }
    }

    // // todo :: remove unnecessary api calls, use store
    // await this.$http
    //   .get(VehicleConfig.api.single(this.id))
    //   .then(res => {
    //     console.log("vehicle-header", res);
    //     this.header = { ...res.data };
    //   })
    //   .catch(err => {
    //     console.log("vehicle-header-err", err);
    //   });
  },
  mounted() {
    this.routeValue =
      this.$router.currentRoute.path ??
      this.tabs?.filter((item) => item.text === 'Profile')[0].href
  },
  methods: {
    changeRoute(event) {
      this.$router.push({ path: event })
    },
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.addEdit.busy = true
        this.$edgeStack.open(this.addEdit.esId)

        const vReq = this.$http.get(useEndpoints.vehicle.details(primaryKey))

        const requests = [vReq]

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              let data = {}
              const [vRes] = responses
              console.log(vRes.data, 'vRes')

              data = { ...vRes.data }

              this.addEdit.stepperMode = 'free'

              this.addEdit.busy = false
              this.addEdit.rawData = data
              this.addEdit.primaryKey = primaryKey // for vehicle
              this.addEdit.primaryKeyLock = data.lock.id // for lock
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.addEdit.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.addEdit.stepperMode = 'strict'

      this.addEdit.busy = false
      this.addEdit.rawData = null // for vehicle
      this.addEdit.primaryKey = null // for vehicle
      this.addEdit.primaryKeyLock = null // for lock

      this.$edgeStack.open(this.addEdit.esId)
      // this.$xStepper.navigate(this.addEdit.esId).to(4)
    },

    onDeleteSucceeded() {
      // this.$store.commit('fsTable/fetchData')
      this.$notify({
        group: 'bottomRight',
        type: 'success',
        title: 'Vehicle Deleted',
        text: `Vehicle has been deleted successfully.`,
      })
    },

    onDeleteFailed({ data }) {
      console.error('onDeleteFailed', data)
      this.$notify({
        group: 'bottomRight',
        type: 'error',
        title: 'Failed to Delete!',
        text: `${data.detail}`,
      })
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/vehicle-management/vehicles/${this.$route.params.id}/${path}`
    },
    handleActivation() {
      console.log('activating...')
    },
    handleDeactivation() {
      console.log('deactivating...')
    },
    handleFlagEvent(eventName) {
      // alert(eventName);
      // EventBus.$emit(VehicleConfig.events.flagData, { a: 1213 });
      console.log('flag-popup', eventName)
      // this.$modal.show(VehicleConfig.events.flagData)
    },
    showGoogleMapModal() {
      // set current popup vehicle data to generate popup subtitle
      // this.mapVehicleData = e;
      // console.log("Fouigjhdefsdxv : ", this.header)
      EventBus.$emit('gmap-popup-alt', this.header)
      this.$modal.show('gmap-popup-alt')
    },
    async handleVehicleLock() {
      // prevent if already req is made
      if (this.lockReq === true) {
        return
      }
      this.lockReq = true

      console.log(this.vehicleLockStatus)

      let url =
        this.vehicleLockStatus === true
          ? VehicleConfig.api.status.lock(this.id)
          : VehicleConfig.api.status.unlock(this.id)

      await this.$http.post(url).then((res) => {
        console.log(res.data)
      })

      await setTimeout(async () => {
        console.log('wait...')

        await this.$http
          .get(VehicleConfig.api.status.current(this.id))
          .then((res) => {
            console.log(res.data)
            // update only if the lock status has changed
            if (res.data.locked !== this.vehicleLockStatus) {
              this.vehicleLockStatus = res.data.locked
            } else {
              alert('Action Failed! Please try again')
            }
          })
          .catch((err) => {
            alert('failed to lock/unlock', err)
          })
          .finally(() => {
            this.lockReq = false
          })
      }, 5000)
    },
    async handleVehicleLockUnlock() {
      if (this.getVehicleStatus === false) {
        alert('The vehicle is not active yet!')
        return
      }
      // prevent if already req is made
      if (this.lockReq === true) {
        return
      }

      this.lockReq = true

      console.log(this.vehicleLockStatus)

      let url =
        this.vehicleLockStatus === true
          ? VehicleConfig.api.status.lock(this.id)
          : VehicleConfig.api.status.unlock(this.id)

      let attempt
      try {
        attempt = this.$http.post(url)
      } catch (error) {
        console.log(error)
      }

      if (attempt) {
        this.getVehicleLockStatus()
      }
    },
    async getVehicleLockStatus(delay = 5000) {
      setTimeout(async () => {
        console.log('wait...')

        await this.$http
          .get(VehicleConfig.api.status.current(this.id))
          .then((res) => {
            console.log(res.data)
            // update only if the lock status has changed
            if (res.data.locked !== this.vehicleLockStatus) {
              this.vehicleLockStatus = res.data.locked
              let mTitle = res.data.locked ? 'Locked' : 'Unlocked'
              this.$notify(
                {
                  group: 'bottomRight',
                  type: 'success',
                  title: mTitle,
                  text: `The vehicle is now ${mTitle.toLowerCase()}`,
                },
                5000
              )
            } else {
              // action is the oposite of the current status
              let msg =
                this.vehicleLockStatus === true
                  ? 'Failed to unlock!'
                  : 'Failed to lock!'
              alert(`${msg} Please try again`)
            }
          })
          .catch(() => {
            let msg =
              this.vehicleLockStatus === true
                ? 'E: Failed to unlock!'
                : 'E: Failed to lock!'
            alert(`${msg} Please try again`)
          })
          .finally(() => {
            this.lockReq = false
          })
      }, delay)
    },
  },
}
</script>
