<template>
  <div>
    <t-modal
      :name="mid"
      variant="popupAlt"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      ref="popup"
    >
      <template v-slot:header>
        <div class="flex items-center px-5 pt-6 pb-4 text-gray-800">
          <display-avatar />
          <div class="ml-5">
            <div class="name">{{ getName }}</div>
            <div class="mt-2 userId">User ID: {{ getUserId }}</div>
          </div>
        </div>
      </template>
      <section>
        <div class="item">
          <div class="item-text">Ride Status</div>
          <div class="item-text">
            <t-dropdown>
              <template
                v-slot:trigger="{
                  mousedownHandler,
                  focusHandler,
                  blurHandler,
                  keydownHandler,
                }"
              >
                <button
                  class="focus:outline-none dropdown-button"
                  :class="getStatusClass"
                  aria-label="Verification Status Dropdown"
                  aria-haspopup="true"
                  @mousedown="mousedownHandler"
                  @focus="focusHandler"
                  @blur="blurHandler"
                  @keydown="keydownHandler"
                >
                  <!-- {{ getStatusText }} -->
                  <i class="mr-2 fas fa-chevron-down"></i>
                  {{ getStatusText }}
                </button>
              </template>
              <template v-slot:default="{ hide }">
                <div
                  @click="hide()"
                  class="py-1 mt-px bg-white rounded-md shadow-lg"
                >
                  <t-dropdown-item
                    class="font-medium uppercase text-14px"
                    @click="handleVerification()"
                  >
                    On Ride
                  </t-dropdown-item>

                  <t-dropdown-item
                    class="font-medium uppercase text-14px"
                    @click="handleDeclining()"
                  >
                    On Reservation
                  </t-dropdown-item>
                </div>
              </template>
            </t-dropdown>
          </div>
        </div>
        <div class="item">
          <div class="item-text">Trip ID</div>
          <div class="item-text">
            {{ getTripId }}
            <i class="pl-3 fas fa-chevron-right text-oGray"></i>
          </div>
        </div>
        <div class="item">
          <div class="item-text">Vehicle ID</div>
          <div class="item-text">
            {{ getVehicleId }}
            <i class="pl-3 fas fa-chevron-right text-oGray"></i>
          </div>
        </div>
        <div class="item">
          <div class="item-text">Location</div>
          <div class="item-text">
            <router-link to="/maps" class="font-medium text-blue-500"
              >View Last Location</router-link
            >
            <i class="pl-3 fas fa-chevron-right text-oGray"></i>
          </div>
        </div>
        <div class="item">
          <div class="item-text">Vehicle Status</div>
          <div class="flex items-center">
            <span class="mr-2 item-text">Unlocked</span>
            <t-toggle variant="success" />
          </div>
        </div>
      </section>

      <template v-slot:footer>
        <div class="flex items-center justify-end px-4 py-4">
          <anchor-button variant="secondary" @click="$refs.popup.hide()"
            >Cancel</anchor-button
          >
          <anchor-button variant="primary" class="ml-2">Save</anchor-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
// SubscriptionConfig
import { VehicleConfig } from '@/config/VehicleConfig'

import { EventBus } from '@/utils/EventBus'
import DisplayAvatar from '@/components/ui/DisplayAvatar'
import AnchorButton from '@/components/form/AnchorButton.vue'
import TDropdownItem from '@/components/dropdown/TDropdownItem'

export default {
  components: { DisplayAvatar, AnchorButton, TDropdownItem },
  props: {
    title: { type: String, required: false, default: '' },
    subtitle: { type: String, required: false, default: '' },
    status: { type: Boolean, required: false, default: undefined },
  },
  data() {
    return {
      mid: VehicleConfig.events.flagData,
      item: {},
    }
  },
  methods: {},
  computed: {
    getName() {
      return 'Hasan Jamil'
    },
    getRideStatus() {
      return 'on-reserved'
    },
    getUserId() {
      return '#11231212l'
    },
    getTripId() {
      return '#4565611'
    },
    getVehicleId() {
      return '#1234512'
    },
    getVehicleStatus() {
      return true
    },
    getStatusText() {
      return this.current_status ? 'On Ride' : 'On Reservation'
    },
    getStatusClass() {
      return this.current_status ? 'verified' : 'unverified'
    },
  },
  mounted() {
    EventBus.$on(VehicleConfig.events.flagData, (item) => {
      console.log({ item })
      this.item = item
    })
  },
}
</script>

<style lang="scss" scoped>
$light-gray: #e2e8f0;
.bg-yellow-custom {
  background-color: #ffeb00;
}
.name {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.userId {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid $light-gray;
  padding: 5px 1.25rem 5px 1.25rem;
  // margin-left: -1rem;
  // margin-right: -1rem;
}
.item-text {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray;
  padding: 0; // 15px 0?
  &.disable-border {
    border-bottom: 0;
  }

  & .spacing {
    padding: 2.5rem;
  }
  & .left {
    width: 60%;
    // padding-right: 0.5rem;
    font-size: 0.875rem;
    padding: 0.875rem;
    font-weight: 500;
  }
  & .right {
    width: 40%;
    // padding-left: 0.5rem;
    padding: 0.875rem;
    border-left: 1px solid $light-gray;
    font-weight: 500;
  }
  & .disable-border-for-right-item {
    border-left: 0;
  }
}
.max-h-80vh {
  max-height: 80vh;
}

.dropdown-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 140px;
  height: 18px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}
.verified {
  background-color: #09aa35;
}
.unverified {
  background-color: #f67606;
}
</style>
