<template>
  <div
    class="w-px h-20 mr-6 opacity-50 bg-oLighterGray"
    :style="`height: ${this.height}`"
  />
</template>

<script>
export default {
  name: 'VerticalDevider',
  props: ['height'],
}
</script>
