<template>
  <div class="ns-container">
    <div v-if="!disableBars" class="bars-container">
      <div class="bar-1" :class="getBarClass({ value, bar: 1 })" />
      <div class="bar-2" :class="getBarClass({ value, bar: 2 })" />
      <div class="bar-3" :class="getBarClass({ value, bar: 3 })" />
      <div class="bar-4" :class="getBarClass({ value, bar: 4 })" />
    </div>

    <div v-if="!disableText" class="text-value" v-text="getText" />
  </div>
</template>

<script>
export default {
  name: 'NetworkStrength',

  props: {
    value: {
      type: Number,
      required: true,
    },

    disableText: {
      type: Boolean,
      default: false,
    },

    disableBars: {
      type: Boolean,
      default: false,
    },

    // max scaleing factor
    scale: {
      type: Number,
      default: 32,
    },

    variant: {
      type: String,
      default: 'small',
      validator: (v) => {
        return ['small'].includes(v)
      },
    },
  },

  computed: {
    getText() {
      let p = 0

      if (this.value > 0) {
        p = (this.value * 100) / 32
      }

      return p.toFixed() + '%'
    },
  },

  methods: {
    getBarClass({ value, bar }) {
      let c = ''
      let max = 0

      if (value >= 0 && value <= 8) {
        c = 'fill-critical'
        max = 1
      }

      if (value > 8 && value <= 16) {
        c = 'fill-low'
        max = 2
      }
      if (value > 16 && value <= 24) {
        c = 'fill-medium'
        max = 3
      }
      if (value > 24) {
        c = 'fill-high'
        max = 4
      }

      console.log(value)
      return bar <= max ? c : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.ns-container {
  display: flex;
  align-items: center;

  .bars-container {
    display: flex;
    align-items: baseline;
    gap: 5px;

    & .bar-1,
    & .bar-2,
    & .bar-3,
    & .bar-4 {
      position: relative;
      width: 7px;
      min-height: 15px;
      border-radius: 2px;
      background-color: #d4d3d3;
    }

    .bar-1 {
      height: 15px;
    }
    .bar-2 {
      height: 20px;
    }
    .bar-3 {
      height: 25px;
    }
    .bar-4 {
      height: 30px;
    }
  }

  .text-value {
    padding-left: 5px;
    font-size: 1.1rem;
    font-weight: bold;
  }
}

.fill-critical {
  background-color: #ca4343 !important;
}
.fill-low {
  background-color: #f7a611 !important;
}
.fill-medium {
  background-color: #75ca43 !important;
}
.fill-high {
  background-color: #61e912 !important;
}

.bg-critical {
  background-color: #ca4343 !important;
}

.bg-low {
  background-color: #f7a611 !important;
}
.bg-medium {
  background-color: #c2e912 !important;
}
.bg-high {
  background-color: #76b850 !important;
}
.bg-gray {
  background-color: #d4d3d3 !important;
}
</style>
