var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{ref:"popup",attrs:{"name":_vm.mid,"variant":"popupAlt","hideCloseButton":true,"disableBodyScroll":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex items-center px-5 pt-6 pb-4 text-gray-800"},[_c('display-avatar'),_c('div',{staticClass:"ml-5"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.getName))]),_c('div',{staticClass:"mt-2 userId"},[_vm._v("User ID: "+_vm._s(_vm.getUserId))])])],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex items-center justify-end px-4 py-4"},[_c('anchor-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$refs.popup.hide()}}},[_vm._v("Cancel")]),_c('anchor-button',{staticClass:"ml-2",attrs:{"variant":"primary"}},[_vm._v("Save")])],1)]},proxy:true}])},[_c('section',[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-text"},[_vm._v("Ride Status")]),_c('div',{staticClass:"item-text"},[_c('t-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
              var mousedownHandler = ref.mousedownHandler;
              var focusHandler = ref.focusHandler;
              var blurHandler = ref.blurHandler;
              var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"focus:outline-none dropdown-button",class:_vm.getStatusClass,attrs:{"aria-label":"Verification Status Dropdown","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('i',{staticClass:"mr-2 fas fa-chevron-down"}),_vm._v(" "+_vm._s(_vm.getStatusText)+" ")])]}},{key:"default",fn:function(ref){
              var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-lg",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',{staticClass:"font-medium uppercase text-14px",on:{"click":function($event){return _vm.handleVerification()}}},[_vm._v(" On Ride ")]),_c('t-dropdown-item',{staticClass:"font-medium uppercase text-14px",on:{"click":function($event){return _vm.handleDeclining()}}},[_vm._v(" On Reservation ")])],1)]}}])})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-text"},[_vm._v("Trip ID")]),_c('div',{staticClass:"item-text"},[_vm._v(" "+_vm._s(_vm.getTripId)+" "),_c('i',{staticClass:"pl-3 fas fa-chevron-right text-oGray"})])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-text"},[_vm._v("Vehicle ID")]),_c('div',{staticClass:"item-text"},[_vm._v(" "+_vm._s(_vm.getVehicleId)+" "),_c('i',{staticClass:"pl-3 fas fa-chevron-right text-oGray"})])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-text"},[_vm._v("Location")]),_c('div',{staticClass:"item-text"},[_c('router-link',{staticClass:"font-medium text-blue-500",attrs:{"to":"/maps"}},[_vm._v("View Last Location")]),_c('i',{staticClass:"pl-3 fas fa-chevron-right text-oGray"})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-text"},[_vm._v("Vehicle Status")]),_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-2 item-text"},[_vm._v("Unlocked")]),_c('t-toggle',{attrs:{"variant":"success"}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }